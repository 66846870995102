<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
        }"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">{{ $t("form.page.faq.search_title") }}</h2>
          <b-card-text class="mb-2">
            {{ $t("form.page.faq.search_description") }}
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Search faq..."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <loading v-if="isLoading"></loading>
    <section id="faq-tabs" v-if="showNoResult == false && !isLoading">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in dataFaq"
          :key="categoryName"
          :active="!index"
        >
          <!-- title -->
          <template #title>
            <feather-icon :icon="categoryObj.icon" size="18" class="mr-1" />
            <span class="font-weight-bold">{{ categoryName }}</span>
          </template>
          <faq-question-answer :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>

    <section id="faq-tabs" v-if="showNoResult == true">
      <div class="w-100 text-center">
        <h2 class="mb-1">Data Not Found</h2>
        <p class="mb-2">
          Oops! The requested for " {{ faqSearchQuery }} " was not found.
        </p>
      </div>
    </section>

    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12" v-if="showNoResult == false">
          <h2>{{ $t("form.page.faq.masih_ada_pertanyaan_title") }}</h2>
          <b-card-text class="mb-3">
            {{ $t("form.page.faq.masih_ada_pertanyaan_description") }}
          </b-card-text>
        </b-col>
        <b-col sm="4">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="PhoneCallIcon" size="18" />
            </b-avatar>
            <br />
            <div style="margin-bottom:-10px;">
              {{ $t("form.page.faq.customer service phone") }}
            </div>
            <br />
            <h4><a href="tel:+622180821777">+62 (21) 8082 1777</a></h4>
            <h4><a href="tel:+622130448777">+62 (21) 3044 8777</a></h4>
            <h4><a href="tel:+623133393777">+62 (31) 3339 3777</a></h4>
            <span class="text-body">{{
              $t("form.page.faq.phone_contact")
            }}</span>
          </b-card>
        </b-col>
        <b-col sm="4">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="MessageCircleIcon" size="18" />
            </b-avatar>
            <div style="margin-bottom:-10px;">
              {{ $t("form.page.faq.customer service wa") }}
            </div>
            <br />
            <h4>
              <a
                :href="
                  'https://wa.me/6285811371894/?text=Halo,%20Saya%20' +
                    getName +
                    '%20' +
                    '( ' +
                    getCustomerId +
                    ' )'
                "
                >085811371894</a
              >
            </h4>
            <span class="text-body">{{ $t("form.page.faq.wa_contact") }}</span>
          </b-card>
        </b-col>
        <b-col sm="4">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="MailIcon" size="18" />
            </b-avatar>
            <div style="margin-bottom:-10px;">
              {{ $t("form.page.faq.customer service email") }}
            </div>
            <br />
            <h4>
              <a href="mailto:customer.service@netciti.co.id"
                >customer.service@netciti.co.id</a
              >
            </h4>
            <span class="text-body">{{
              $t("form.page.faq.email_contact")
            }}</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
} from "bootstrap-vue";
import FaqQuestionAnswer from "./FaqQuestionAnswer.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import Loading from "@core/components/loading/Loading.vue";

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
    ToastificationContent,
    Loading,
  },
  data() {
    return {
      faqSearchQuery: "",
      dataFaq: [],
      isLoading: false,
      showNoResult: false,
      faqData: {
        // payment
        payment: {
          icon: "CreditCardIcon",
          title: "Payment",
          subtitle: "Which license do I need?",
          qandA: [
            {
              question: "Does my subscription automatically renew?",
              ans:
                "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
            },
            {
              question:
                "Can I store the item on an intranet so everyone has access?",
              ans:
                "Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.",
            },
            {
              question: "What does non-exclusive mean?",
              ans:
                "Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.",
            },
            {
              question:
                "Is the Regular License the same thing as an editorial license?",
              ans:
                "Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.",
            },
            {
              question:
                "Which license do I need for an end product that is only accessible to paying users?",
              ans:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
            },
            {
              question:
                "Which license do I need to use an item in a commercial?",
              ans:
                "At tempor commodo ullamcorper a lacus vestibulum. Ultrices neque ornare aenean euismod. Dui vivamus arcu felis bibendum. Turpis in eu mi bibendum neque egestas congue. Nullam ac tortor vitae purus faucibus ornare suspendisse sed.",
            },
            {
              question:
                "Can I re-distribute an item? What about under an Extended License?",
              ans:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod lacinia at quis risus sed vulputate odio ut enim. Dictum at tempor commodo ullamcorper a lacus vestibulum.",
            },
          ],
        },
        // delivery
        tickets: {
          icon: "ShoppingBagIcon",
          title: "Tickets",
          subtitle: "Which license do I need?",
          qandA: [
            {
              question: "Where has my order reached?",
              ans:
                "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
            },
            {
              question:
                "The shipment status shows that it has been returned/cancelled. What does it mean and who do I contact?",
              ans:
                "Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.",
            },
            {
              question: "What if my shipment is marked as lost?",
              ans:
                "Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.",
            },
            {
              question:
                "My shipment status shows that it’s out for delivery. By when will I receive it?",
              ans:
                "Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.",
            },
            {
              question:
                "What do I need to do to get the shipment delivered within a specific timeframe?",
              ans:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
            },
          ],
        },
        // cancellation and return
        tvchannel: {
          icon: "RefreshCwIcon",
          title: "Internet & TV Channel",
          subtitle: "Which license do I need?",
          qandA: [
            {
              question:
                "Can my security guard or neighbour receive my shipment if I am not available?",
              ans:
                "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
            },
            {
              question:
                "How can I get the contact number of my delivery agent?",
              ans:
                "Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.",
            },
            {
              question: "How can I cancel my shipment?",
              ans:
                "Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.",
            },
            {
              question:
                "I have received a defective/damaged product. What do I do?",
              ans:
                "Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.",
            },
            {
              question: "How do I change my delivery address?",
              ans:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
            },
            {
              question:
                "What documents do I need to carry for self-collection of my shipment?",
              ans:
                "At tempor commodo ullamcorper a lacus vestibulum. Ultrices neque ornare aenean euismod. Dui vivamus arcu felis bibendum. Turpis in eu mi bibendum neque egestas congue. Nullam ac tortor vitae purus faucibus ornare suspendisse sed. Commodo viverra maecenas accumsan lacus vel facilisis volutpat est velit. Tortor consequat id porta nibh. Id aliquet lectus proin nibh nisl condimentum id venenatis a. Faucibus nisl tincidunt eget nullam non nisi. Enim nunc faucibus a pellentesque. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Nec nam aliquam sem et tortor consequat id. Fringilla est ullamcorper eget nulla facilisi. Morbi tristique senectus et netus et.",
            },
            {
              question:
                "What are the timings for self-collecting shipments from the Delhivery Branch?",
              ans:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod lacinia at quis risus sed vulputate odio ut enim. Dictum at tempor commodo ullamcorper a lacus vestibulum.",
            },
          ],
        },
        // my orders
        billing: {
          icon: "PackageIcon",
          title: "Billing",
          subtitle: "Which license do I need?",
          qandA: [
            {
              question: "Can I avail of an open delivery?",
              ans:
                "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
            },
            {
              question:
                "I haven’t received the refund of my returned shipment. What do I do?",
              ans:
                "Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.",
            },
            {
              question: "How can I ship my order to an international location?",
              ans:
                "Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.",
            },
            {
              question:
                "I missed the delivery of my order today. What should I do?",
              ans:
                "Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.",
            },
            {
              question:
                "The delivery of my order is delayed. What should I do?",
              ans:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
            },
          ],
        },
        // product and services
        productServices: {
          icon: "SettingsIcon",
          title: "Product & Services",
          subtitle: "Which license do I need?",
          qandA: [
            {
              question:
                "How can I register a complaint against the courier executive who came to deliver my order?",
              ans:
                "Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.",
            },
            {
              question:
                "The status for my shipment shows as ‘not picked up’. What do I do?",
              ans:
                "Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.",
            },
            {
              question: "How can I get a proof of delivery for my shipment?",
              ans:
                "Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.",
            },
            {
              question: "How can I avail your services?",
              ans:
                "Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.",
            },
          ],
        },
      },
    };
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.faqSearchQuery = this.faqSearchQuery.replace(/[^a-zA-Z ]/g, "");
        this.fetchData();
      },
    },
  },
  computed: {
    getName: function() {
      return localStorage.getItem("name");
    },
    getCustomerId: function() {
      return localStorage.getItem("customer_code");
    },
  },
  methods: {
    init() {
      this.fetchData();
    },
    callingto(e) {
      window.location.href = "tel:+" + e;
    },
    chatto(Name, CustomerId) {
      window.location.href =
        "https://wa.me/6285811371894/?text=Halo,%20Saya%20" +
        Name +
        "%20" +
        "( " +
        CustomerId +
        " )";
    },
    mailto() {
      window.location.href = "mailto:customer.service@netciti.co.id";
    },
    fetchData() {
      this.showNoResult = false;
      this.isLoading = true;
      axios
        .post("faq/data", { search: this.faqSearchQuery })
        .then((response) => {
          this.dataFaq = response.data.data;
          if (this.dataFaq.length == 0) {
            this.showNoResult = true;
          }
          //console.log(this.dataFaq.length);
        })
        .catch((error) => {
          if (error.response.data.message == "Expired token") {
            localStorage.clear();
            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Load Dashboard",
                icon: "EditIcon",
                variant: "success",
                text: "Session Expired, Please Login Again",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Load Dashboard",
                icon: "EditIcon",
                variant: "success",
                text: error.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    searchData() {},
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
